import {
    IonAlert,
    IonButtons, IonContent, IonHeader, IonPage, IonProgressBar, IonTitle,
    IonToolbar
} from '@ionic/react';
import React, {Component, PropsWithChildren} from 'react';
import './Users.css';
import {IonBackButton} from "@ionic/react";
import {withAuth} from "../components/withAuth";
import {connect} from "react-redux";
import {AuthStateHook} from "react-firebase-hooks/auth/dist/auth/useAuthState";
import {LogoutToast} from "../components/LogoutToast";
import {ErrorToast} from "../components/ErrorToast";
import ProfileButton from '../components/ProfileButton';

import {signOut} from "../reducers/authenticatedSlice";
import {setLogoutNeeded} from "../reducers/requestStatusSlice";
import axios from "axios";
import {getToken} from "../repositories/TokenRepository";


export interface PageProperties extends PropsWithChildren {
    title: string;
    backButtonUrl?: string;
    authState: AuthStateHook;
    showProgressBar: boolean;
    requestError: string | null;
    showLogoutAlert: boolean;
    signOut: any;
    setLogoutNeeded: any;
}

export interface PageState {
    authCheckIntervalId: any;
}


class Page extends Component<PageProperties, PageState> {
    showProgressBar() {
        return this.props.showProgressBar
            ? <IonProgressBar type="indeterminate"></IonProgressBar>
            : ''
    }

    componentDidMount() {
        const checkEndpoint = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/signedIn`, {
                    headers: {
                        'Api-Key': `Bearer ${getToken()}`
                    }
                });
                if (response.status === 401 || response.status === 403) {
                    this.props.setLogoutNeeded(true);
                }
            } catch (error) {
                if ((error as any).response.status === 401 || (error as any).response.status === 403) {
                    this.props.setLogoutNeeded(true);
                }
                console.warn(error);
            }
        };

        checkEndpoint();
        this.setState({authCheckIntervalId: setInterval(checkEndpoint, 5000)});
    }

    componentWillUnmount() {
        if (typeof this.state.authCheckIntervalId !== 'undefined') {
            clearInterval(this.state.authCheckIntervalId);
            this.setState({authCheckIntervalId: undefined});
        }
    }

    logoutAfterAccessDenied() {
        this.props.setLogoutNeeded(false);
        this.props.signOut();
    }

    showBackButton() {
        if (!this.props.backButtonUrl) return '';
        return <IonButtons slot="start">
            <IonBackButton defaultHref={this.props.backButtonUrl}/>
        </IonButtons>
    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        {this.showBackButton()}
                        <IonTitle>{this.props.title}</IonTitle>
                        <IonButtons slot="end">
                            <ProfileButton />
                        </IonButtons>
                        {this.showProgressBar()}
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    {this.props.children}
                </IonContent>
                <LogoutToast/>
                <ErrorToast error={this.props.requestError} />
                <IonAlert isOpen={this.props.showLogoutAlert}
                          message="Access denied. You will be logged out"
                          buttons={[{ text: 'Ok' }]}
                          onDidDismiss={this.logoutAfterAccessDenied.bind(this)} />
            </IonPage>
        )
    }
}

const mapStateToProps = (state: any) => ({
    showProgressBar: state.requestStatus.requestsInProgress > 0,
    requestError: state.requestStatus.error,
    showLogoutAlert: state.requestStatus.logoutNeeded
});

const mapDispatchToProps = {signOut, setLogoutNeeded};

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(Page));
